import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import FaqItem from "components/FaqItem";

const Faq = ({ contentModuleId }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulLayoutFaq {
        edges {
          node {
            id
            title
            faqItem {
              id
              title
              description {
                description
              }
            }
          }
        }
      }
    }
  `);

  const content = data.allContentfulLayoutFaq.edges.find(
    edge => edge.node.id === contentModuleId
  );

  return (
    <section id="faq" className="section bg-gray">
      <div className="container mx-auto">
        {content.node.title && (
          <h2
            className="section__title text-center mb-16"
            data-sal="fade"
            data-sal-easing="ease-in-cubic"
          >
            {content.node.title}
          </h2>
        )}
        <div className="w-full md:w-2/4 mx-auto mt-10">
          {content.node.faqItem.map(item => (
            <FaqItem key={item.id} data={item} />
          ))}
        </div>
      </div>
    </section>
  );
};

Faq.propTypes = {
  contentModuleId: PropTypes.string.isRequired,
};

export default Faq;
