import React, { useState } from "react";

const FaqItem = ({ data }) => {
  const [active, setActive] = useState(false);
  if (!data) {
    return null;
  }
  return (
    <div
      data-sal="fade"
      data-sal-easing="ease-in-cubic"
      data-sal-duration="400"
    >
      <>
        <div
          onClick={() => setActive(!active)}
          className="px-4 py-3 flex rounded-xl bg-white mb-3"
        >
          <div className="flex-grow self-center">
            <h1 className="text-xl hover:text-primary font-medium my-0 text-base cursor-pointer select-none">
              {data.title}
            </h1>
          </div>
          <span className="flex-grow-0 text-2xl text-purple ml-3 cursor-pointer">
            {active ? (
              <i className="item__icon material-icons text-black text-2xl">
                remove
              </i>
            ) : (
              <i className="item__icon material-icons text-black text-2xl">
                add
              </i>
            )}
          </span>
        </div>
        {active && (
          <div className="px-4 pb-4">
            {data.description && (
              <h1 className="font-normal my-0 text-base my-0">
                {data.description.description}
              </h1>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default FaqItem;
