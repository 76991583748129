import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import addToMailchimp from "gatsby-plugin-mailchimp";

const ContactForm = ({
  values,
  touched,
  errors,
  isSubmitting,
  handleSubmit,
  handleChange,
  handleBlur,
}) => {
  return (
    <form
      method="post"
      onSubmit={handleSubmit}
      id="contact_form"
      className="w-full md:w-3/4"
      noValidate
      data-sal="slide-up"
      data-sal-easing="ease-in-cubic"
      data-sal-delay="100"
    >
      <div className="input-group mb-2">
        <label htmlFor="name">Name</label>
        <input
          id="name"
          className="input"
          type="text"
          name="name"
          placeholder="John doe"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      {errors.name && touched.name && (
        <p className="text-primary text-xs">{errors.name}</p>
      )}

      <div className="input-group mb-2">
        <label htmlFor="email">Email</label>
        <input
          id="email"
          className="input"
          type="email"
          name="email"
          placeholder="john@doe.com"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      {errors.email && touched.email && (
        <p className="text-primary text-xs">{errors.email}</p>
      )}

      <div className="input-group">
        <label htmlFor="message">Message</label>
        <textarea
          className="h-20"
          id="message"
          type="text"
          placeholder="Message"
          value={values.message}
          onChange={handleChange}
          onBlur={handleBlur}
          name="message"
        />
      </div>
      {errors.message && touched.message && (
        <p className="text-primary text-xs">{errors.message}</p>
      )}

      <button
        disabled={isSubmitting}
        type="submit"
        className={`button btn btn--primary mt-8 ${
          isSubmitting && "cursor-not-allowed"
        }`}
      >
        Submit
      </button>
    </form>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    email: "",
    name: "",
    message: "",
  }),
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required!"),
    name: Yup.string().required("Name is required!"),
  }),
  handleSubmit: (values, { setSubmitting, resetForm }) => {
    addToMailchimp(values.email, {
      NAME: values.name,
      MESSAGE: values.message,
    })
      .then(() => {
        swal("We contact you shortly", "Thank you!", "success");
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
    resetForm();
  },
  displayName: "ContactForm", // helps with React DevTools
})(ContactForm);
