import React from "react";
import PropTypes from "prop-types";

import Hero from "sections/Hero";
import About from "sections/About";
import Testimonials from "sections/Testimonials";
import Services from "sections/Services";
import Faq from "sections/Faq";
import Contact from "sections/Contact";
import PageNotFound from "sections/PageNotFound";

// Dynamically import or require sections inside the section folder
const components = {
  Hero,
  About,
  Testimonials,
  Services,
  Faq,
  Contact,
  PageNotFound,
};

const Section = ({ contentModuleId, type }) => {
  const component = type.split("Layout")[1];
  if (typeof components[component] === "undefined") {
    return "";
  }

  return React.createElement(components[component], {
    contentModuleId,
  });
};

Section.prototype = {
  contentModuleId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Section;
